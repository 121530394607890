@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* scrollbar styles */
::-webkit-scrollbar {
  @apply w-1 h-3 rounded;
}
::-webkit-scrollbar-thumb {
  @apply bg-accent/50 rounded;
}
::-webkit-scrollbar-thumb:hover {
  @apply bg-accent;
}

@layer base {
  html,
  body {
    @apply font-inter;
  }

  img {
    @apply max-w-full max-h-full object-cover;
  }

  p {
    @apply text-body !text-sm;
  }

  .primary-text {
    @apply text-primary;
  }
}

@layer components {
  .status {
    @apply px-2.5 py-1 select-none text-xs font-medium leading-tight rounded-sm;
  }
  .status-done {
    @apply status text-status-complete-text bg-status-complete-bg;
  }
  .status-danger {
    @apply status text-status-danger-text bg-status-danger-bg;
  }
  .status-pending {
    @apply status text-status-pending-text bg-status-pending-bg;
  }
  .status-waiting {
    @apply status text-status-waiting-text bg-status-waiting-bg;
  }
  .status-info {
    @apply status text-status-info-text bg-status-info-bg;
  }
  .status-draft {
    @apply status text-status-draft-text bg-status-draft-bg;
  }

  .btn-status {
    @apply !text-white !rounded-lg !border-none !text-sm !font-medium;
  }
  .btn-status-danger {
    @apply !bg-status-danger-btn disabled:!bg-status-danger-btn/50 hover:!bg-status-danger-btn/90;
  }
  .btn-status-done {
    @apply !bg-status-complete-btn  disabled:!bg-status-complete-btn/50 hover:!bg-status-complete-btn/90;
  }
  .btn-status-waiting {
    @apply !bg-status-waiting-btn disabled:!bg-status-waiting-btn/50 hover:!bg-status-waiting-btn/90;
  }
  .btn-status-info {
    @apply !bg-status-info-btn disabled:!bg-status-info-btn/50 hover:!bg-status-info-btn/90;
  }
  .btn-status-pending {
    @apply !bg-status-pending-btn disabled:!bg-status-pending-btn/50 hover:!bg-status-pending-btn/90;
  }

  .disable-btn,
  .paid_btn .ant-btn-default {
    @apply !bg-others-gray hover:!bg-others-gray/90 disabled:!bg-others-gray !rounded-lg !text-white !text-base !border-others-gray !font-medium leading-7;
  }
  /* .btn-status {
    @apply text-xs select-none rounded-lg py-3 px-4 inline-block text-primary font-semibold;
  }
   */

  .primary-btn {
    @apply !bg-primary hover:!bg-primary/90 !text-white !shadow-md hover:!shadow;
  }
  .secondary-btn {
    @apply !bg-secondary hover:!bg-secondary/90 !text-white !shadow-md hover:!shadow;
  }
  .recharge-btn {
    @apply !bg-others-secondary hover:!bg-others-secondary/90 !text-white !shadow-md hover:!shadow;
  }
  .upload-btn {
    @apply !bg-white hover:!bg-white/90 !text-status-pending-text border-status-pending-text !shadow-sm hover:!border-status-pending-text/90 hover:!shadow;
  }

  .normal_btn {
    @apply !bg-status-info-bg hover:!bg-status-info-bg/90 !text-others-accent !border-status-info-bg !shadow-sm hover:!shadow text-sm !rounded-lg disabled:!shadow-none;
  }

  .cancel_btn {
    @apply bg-others-cancel !border-others-cancel !min-w-[5rem] !rounded !text-sm !font-medium !leading-normal !text-others-header;
  }
  .draft_btn {
    @apply bg-status-danger-bg !border-status-danger-bg !min-w-[5rem] !rounded !text-sm !font-medium !leading-normal !text-primary;
  }

  .primary_btn {
    @apply flex justify-center items-center min-w-[151px] !rounded !text-white !text-sm !font-medium !leading-normal;
  }
  .download_btn {
    @apply !bg-[#FFDCDA] disabled:!bg-[#FFDCDA]/50 !px-[15px] !py-2 !rounded-sm !text-sm !font-medium !leading-normal !text-primary !border-transparent;
  }
}

/* Order Status start */
.status-sent {
  @apply status text-[#7f56f8] bg-[#f1ecfe];
}
.status-so-pending {
  @apply status text-[#e82215] bg-[#ffdcd9];
}
.status-delivered {
  @apply status text-[#10a65c] bg-[#f2fff8];
}
.status-rts {
  @apply status text-[#5e718d] bg-[#f0f3f8];
}
.status-shipped {
  @apply status text-[#4a71ff] bg-[#f2f5ff];
}
.status-cancel {
  @apply status text-[#ff3838] bg-[#fff4f4];
}

.status-outline-credit {
  @apply status !rounded text-[#d8a800] border border-solid border-[#d8a800];
}
.status-outline-cash {
  @apply status !rounded text-[#4a71ff] border border-solid border-[#b4c4ff];
}
.status-outline-pdc {
  @apply status !rounded text-[#5e718d] border border-solid border-[#d7dee8];
}
/* Order Status end */

/* ====== buoy icon animation ======> */
.buoyIcon {
  -webkit-animation: buoyIcon 2s linear infinite;
  animation: buoyIcon 2s linear infinite;
}

@keyframes buoyIcon {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/* <====== buoy icon animation ====== */

/* change scrollbar color in sidebar */
.custom-sidebar {
  scrollbar-color: #fff #fff !important;
  scrollbar-width: none !important;
}

.custom-sidebar:hover {
  scrollbar-width: thin !important;
}

.custom-sidebar:hover,
.custom_scrollbar .ant-table-content:hover {
  scrollbar-color: #ddd #fff !important;
}

.custom_scrollbar .ant-table-content {
  scrollbar-color: #fff #fff !important;
  scrollbar-width: thin !important;
}

/* mapbox */
.mapboxgl-control-container {
  @apply !hidden;
}

.dashboard_map .mapboxgl-canvas {
  @apply !rounded-xl !w-full object-cover;
}

.mapboxgl-canvas {
  @apply !w-full;
}

.info_items {
  @apply border border-solid border-[#afbaca];
}

.info_items > div {
  @apply border-b border-t-0 border-x-0 border-solid border-[#afbaca] last:border-b-0;
}

.show_answer_left_border > div > h4 {
  @apply !text-sm py-2;
}
.show_answer_left_border > div > span {
  @apply border-l border-y-0 border-r-0 border-solid border-[#afbaca] !text-sm py-2;
}

/* border line look table */
.border_b_table {
  @apply px-2 py-1.5 border border-solid border-others-accent border-t-0 border-l-0 text-body text-sm font-medium leading-normal;
}

/* print design */
@media print {
  .common_print > h4,
  .payment_details > h4 {
    display: block;
  }

  .common_print .filter_section {
    @apply hidden;
  }

  /* .common_print table tr th:last-child,
  .common_print table tr td:last-child {
    @apply !hidden;
  } */
}

/* hr */
.hr {
  border: 0;
  height: 0;
  border-bottom: 1px solid #d7dee8;
}

/* input number hide arrows */

/* Chrome, Safari, Edge, Opera */
input.hide_number_arrow::-webkit-outer-spin-button,
input.hide_number_arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.hide_number_arrow[type="number"] {
  -moz-appearance: textfield;
}

.custom_pdf {
  @apply w-full h-[80vh] min-h-[300px];
}

#custom_pdf_viewer {
  @apply w-full;
}

#custom_pdf_viewer canvas {
  @apply w-full h-full;
}

.extra_text_style {
  @apply text-body text-xs font-normal leading-tight;
}
